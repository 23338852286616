<template>
  <div
    :class="
      role
        ? '_bg-default _80w _100vh pa-3 d-flex justify-center align-center '
        : '_bg-default _full-w _100vh pa-3 d-flex justify-center align-center'
    "
  >
    <div class="pa-3 mb-3 d-flex justify-center align-center">
      <v-img
        src="http://pampsi.gamifindo.com/images/logo.png"
        height="200px"
        width="200px"
        class="mr-5"
      ></v-img>
      <div>
        <h3>"Terimakasih telah mengisi survey!"</h3>
        <v-btn dark color="purple" to="/public/survey/list"
          ><v-icon>mdi-arrow-left-circle</v-icon> Kembali</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      role: state => state.role
    })
  }
};
</script>

<style></style>
